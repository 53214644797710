import { Injectable } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
// import {
//   MENU_ITEMS_CustomeMenu,
// } from '../../fp-menu-item';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true;

  constructor() { }

  public setMenu(): NbMenuItem[] {

    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);

    const MENU_ITEMS: NbMenuItem[] = [];
    const Dashboard: NbMenuItem = {
      title: 'Dashboard',
      icon: 'layout-outline',
      link: '/dashboard/akdashboard',
    };

    const Master: NbMenuItem = {
      title: 'Masters',
      icon: 'layout-outline',
      children: []
    };

    // const User: NbMenuItem = {
    //   title: 'User',
    //   icon: 'layout-outline',
    //   children: []
    // };

    // const Inventory: NbMenuItem = {
    //   title: 'Inventory',
    //   icon: 'layout-outline',
    //   children: []
    // };

    // const customerCare: NbMenuItem = {
    //   title: 'Customer Care',
    //   icon: 'layout-outline',
    //   children: []
    // };

    // const ReleasePayment: NbMenuItem = {
    //   title: 'Release Payment',
    //   icon: 'layout-outline',
    //   children: []
    // };

    // const OtherList: NbMenuItem = {
    //   title: 'Other List',
    //   icon: 'layout-outline',
    //   children: []
    // };

    const Reports: NbMenuItem = {
      title: 'Reports',
      icon: 'layout-outline',
      link: '/masters/report'
    };


    MENU_ITEMS.push(Dashboard); // 0
    MENU_ITEMS.push(Master); // 1
    // MENU_ITEMS.push(User); // 2
    // MENU_ITEMS.push(Inventory); // 3
    // MENU_ITEMS.push(customerCare); // 4
    // MENU_ITEMS.push(ReleasePayment); // 5
    // MENU_ITEMS.push(OtherList); // 6
    MENU_ITEMS.push(Reports); // 7

    if (role.page) {
      role.page.forEach(item => {
        // Master
        if (item.page === 'City' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              title: 'City',
              link: '/masters/city',
            }
          );
        }

        if (item.page === 'Area' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              title: 'Area',
              link: '/masters/area/0',
            }
          );
        }

        if (item.page === 'Area rate board' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              title: 'Area Rate Board',
              link: '/masters/area-rate-board/0/0/0',
            }
          );
        }
        if (item.page === 'Grain' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              title: 'Category',
              link: '/masters/grain',
            }
          );
        }
        if (item.page === 'Sub-Grain' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              title: 'Sub-Category',
              link: '/masters/subgrain',
            }
          );
        }
        if (item.page === 'Product Form' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              title: 'Product',
              link: '/product/productform',
            }
          );
        }
        if (item.page === 'Delivery Slot' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              title: 'Delivery Slot',
              link: '/masters/delivery-slot',
            }
          );
        }
        if (item.page === 'Vendor' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              title: 'Vendor',
              link: '/masters/vendor-form',
            }
          );
        }
        if (item.page === 'Salary Grade' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              title: 'Salary Grade',
              link: '/masters/salary-grad',
            }
          );
        }
        if (item.page === 'Business Value Factor' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              title: 'Business Value Factor',
              link: '/masters/setting',
            }
          );
        }

        if (item.page === 'Banner' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
          {
              title: 'Banner',
              link: '/product/banner',
            }
          );
        }

        if (item.page === 'warehouse' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
              title: 'Warehouse',
              link: '/stock/warehouse',
            }
          );
        }

        // User ------------------------------------------------------------------

        // if (item.page === 'FlourPicker User' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[2].children.push(
        //     {
        //       title: 'User ',
        //       link: '/product/fk-user',
        //     }
        //   );
        // }
        // if (item.page === 'Applied Business Partner' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[2].children.push(
        //     {
        //       title: 'Applied Business Partner',
        //       link: '/product/applied',
        //     }
        //   );
        // }
        // if (item.page === 'Business Partner' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[2].children.push(
        //     {
        //       title: 'Business Partner',
        //       link: '/product/businessPartner/0',
        //     }
        //   );
        // }
        // if (item.page === 'Business Partner Rate Board' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[2].children.push(
        //     {
        //       title: 'Business Partner Rate Board',
        //       link: '/masters/bp-rate-board/0/0',
        //     }
        //   );
        // }
        // if (item.page === 'Business Partner Stock Capacity' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[2].children.push(
        //     {
        //       title: 'Business Partner Stock Capacity',
        //       link: '/masters/gp-stock-capacity/0',
        //     }
        //   );
        // }
        // if (item.page === 'Employee' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[2].children.push(
        //     {
        //       title: 'Employee',
        //       link: '/masters/employee',
        //     }
        //   );
        // }
        // if (item.page === 'Delivery boy' && (item.isRead || item.isWrite)) {

        //   MENU_ITEMS[2].children.push(
        //     {
        //       title: 'Delivery Boy',
        //       link: '/product/deliveryboy',
        //     }
        //   );
        // }

        // Inventory  ----------------------------------------
        // if (item.page === 'Stock in' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[3].children.push(
        //     {
        //       title: 'Stock In',
        //       link: '/stock/stockin',
        //     }
        //   );
        // }

        // if (item.page === 'Stock out' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[3].children.push(
        //     {
        //       title: 'Stock Out',
        //       link: '/stock/stockout',
        //     }
        //   );
        // }

        // if (item.page === 'Company stock' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[3].children.push(
        //     {
        //       title: 'Company Stock',
        //       link: '/stock/companyStock',
        //     }
        //   );
        // }

        // if (item.page === 'Business partner stock' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[3].children.push(
        //     {
        //       title: 'Business Partner Stock',
        //       link: '/stock/gpartnerStock',
        //     }
        //   );
        // }

        // if (item.page === 'readymatestockin' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[3].children.push(
        //     {
        //       title: 'Readymade Stock In',
        //       link: '/stock/rstockin',
        //     }
        //   );
        // }

        // if (item.page === 'warehousestocklist' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[3].children.push(
        //     {
        //       title: 'Readymade Stock',
        //       link: '/stock/warehouseStock',
        //     }
        //   );
        // }

        // Customer Care
        // if (item.page === 'Tickets' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[4].children.push(
        //     {
        //       title: 'Tickets',
        //       link: '/care/tickets',
        //     }
        //   );
        // }
        // if (item.page === 'Add tickets' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[4].children.push(
        //     {
        //       title: 'Add Tickets',
        //       link: '/care/add-tickets',
        //     }
        //   );
        // }
        // Release Payment    ----------------------------------------------------------------
        // if (item.page === 'Business partnerPayment' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[5].children.push(
        //     {
        //       title: 'Business Partner',
        //       link: '/payment/rel-bp-payment',
        //     }
        //   );
        // }
        // if (item.page === 'Delivery boyPayment' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[5].children.push(
        //     {
        //       title: 'Delivery  Boy',
        //       link: '/payment/rel-db-payment',
        //     }
        //   );
        // }

        // if (item.page === 'cod' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[5].children.push(
        //     {
        //       title: 'Delivery boy Collection',
        //       link: '/stock/cod',
        //     }
        //   );
        // }
        // Other List -------------------------------------------------------

        // if (item.page === 'Recipe' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[6].children.push(
        //     {
        //       title: 'Recipe',
        //       link: '/masters/recipe',
        //     }
        //   );
        // }
        // if (item.page === 'Rating' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[6].children.push(
        //     {
        //       title: 'Rating',
        //       link: '/masters/rating',
        //     }
        //   );
        // }
        // if (item.page === 'Review' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[6].children.push(
        //     {
        //       title: 'Review',
        //       link: '/masters/review',
        //     }
        //   );
        // }
        // if (item.page === 'Help & support' && (item.isRead || item.isWrite)) {
        //   MENU_ITEMS[6].children.push(
        //     {
        //       title: 'Help & Support',
        //       link: '/masters/help',
        //     }
        //   );
        // }

        // Reports

      });

      // remove unnesessary items To DO

      // check for reports   7 MENU_ITEMS.push(Dashboard); // 0


      const reportResult = role.page.find(repo =>

        (repo.page === 'User Reports' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Business Partner' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Business Partner StockReport' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Business Partner Consume Stock' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'All Bussiness partner stock report' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Business partner rate boardReport' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Current order for delivery boy' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Current Order for Grinding Partner' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Area stock' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Order Reports' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'User Wallet' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Source of payment' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Order Number with Charges' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Pass order by day' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'CurrentOrderforGrindingPartner' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Completed Order' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Delivery Boy Km travel' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'SaleReport' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'MultigrainandRecipeOrderReport' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'InventoryReport' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'PurchaseReport' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'CustomerwithZeroOrders' && (repo.isRead || repo.isWrite))

      );

      if (!reportResult) {
        MENU_ITEMS.splice(7, 1);
      }


      // MENU_ITEMS.push(OtherList); // 6
      // const otherListResult = role.page.find(repo =>
      //   (repo.page === 'Recipe' && (repo.isRead || repo.isWrite)) ||
      //   (repo.page === 'Rating' && (repo.isRead || repo.isWrite)) ||
      //   (repo.page === 'Review' && (repo.isRead || repo.isWrite)) ||
      //   (repo.page === 'Help & support' && (repo.isRead || repo.isWrite))
      // );

      // if (!otherListResult) {
      //   MENU_ITEMS.splice(6, 1);
      // }

      // MENU_ITEMS.push(ReleasePayment); // 5

      const releasePaymentResult = role.page.find(repo =>
        (repo.page === 'Business partner' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Delivery boy' && (repo.isRead || repo.isWrite))
      );

      if (!releasePaymentResult) {
        MENU_ITEMS.splice(5, 1);
      }


      //  MENU_ITEMS.push(customerCare); // 4

      const customerCareResult = role.page.find(repo =>
        (repo.page === 'Tickets' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Add tickets' && (repo.isRead || repo.isWrite))
      );

      if (!customerCareResult) {
        MENU_ITEMS.splice(4, 1);
      }
      // MENU_ITEMS.push(Inventory); // 3

      const inventoryResult = role.page.find(repo =>
        (repo.page === 'Stock in' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Stock out' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Company stock' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Business partner stock' && (repo.isRead || repo.isWrite))
      );

      if (!inventoryResult) {
        MENU_ITEMS.splice(3, 1);
      }

      // MENU_ITEMS.push(User); // 2

      const userResult = role.page.find(repo =>
        (repo.page === 'FlourPicker User' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Applied Business Partner' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Business partner rate board' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Business partner stock capacity' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Employee' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Delivery boy' && (repo.isRead || repo.isWrite))
      );

      if (!userResult) {
        MENU_ITEMS.splice(2, 1);
      }

      // MENU_ITEMS.push(Master); // 1

      const masterResult = role.page.find(repo =>
        (repo.page === 'City' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Area' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Area rate board' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Grain' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Sub-Grain' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Product Form' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Delivery Slot' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Vendor' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Business Value Factor' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Banner' && (repo.isRead || repo.isWrite))
      );

      if (!masterResult) {
        MENU_ITEMS.splice(1, 1);
      }

      // dashboard 0

      const dashboardResult = role.page.find(repo =>
        (repo.page === 'NewOrder' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'AccpetedOrder' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'OrderInProgess' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'PassedOrderbyBusinessPartner' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'OrderCompletedbyBusinessPartner' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'PassedOrderbyDeliveryBoy' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'PickUpOrderList' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'OrderComplitedbyDeliveryBoy' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'CancleOrder' && (repo.isRead || repo.isWrite))
      );

      if (!dashboardResult) {
        MENU_ITEMS.splice(0, 1);
      }
    }
    return MENU_ITEMS;
  }
}

