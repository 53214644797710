import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { IOrder, IOrderDetails, IOrders, IWaiterOrderListTable } from '../../models/order.model';
import { OrderService } from '../../Shared/Services/order.service';
import { map, take } from 'rxjs/operators';
import { WaiterOrderComponent } from '../waiter-order/waiter-order.component';
import { NbDialogService } from '@nebular/theme';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CounterOrderComponent } from '../counter-order/counter-order.component';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'ngx-fpdashboard',
  templateUrl: './fpdashboard.component.html',
  styleUrls: ['./fpdashboard.component.scss']
})
export class FpdashboardComponent implements OnInit, OnDestroy {
  mySubscription: Subscription
  countAll: any;
  loader = false;
  userInfo: any;
  pageRole: any;
  roleId: number;
  orderList: any[] ;
  copyOrderList: any[] ;

  orders: IOrder[];
  subtotal = 0;
  total = 0;
  tax = 0;

  public data: any;

  intervalId: any;
  tokenRefresh: any;

  name = 'Set iframe source';
  url: string = "https://foodwaiter.knackbe.in/#/";
  urlSafe: SafeResourceUrl;

  NewOrder: boolean = true;
  AccpetedOrder: boolean = true;
  OrderInProgess: boolean = true;
  PassedOrderbyBusinessPartner: boolean = true;
  OrderCompletedbyBusinessPartner: boolean = true;
  PassedOrderbyDeliveryBoy: boolean = true;
  PickUpOrderList: boolean = true;
  OrderComplitedbyDeliveryBoy: boolean = true;
  CancleOrder: boolean = true;

  constructor(
    private orderService: OrderService,
    private dialogService: NbDialogService,
    public sanitizer: DomSanitizer,

  ) { }

  ngOnInit() {
    this.mySubscription = timer(0, 30000).pipe(
      map(() => {
        this.getWaiterOrderList(); // load order data contains the http request
      })
    ).subscribe();
    // this.getTotalAmount();

    const infostr = (localStorage.getItem('info'));
    const info = JSON.parse(infostr);
    this.userInfo = info;

    this.getAllCountList(true);
    this.intervalId = setInterval(() => {
      this.getAllCountList(false);

    }, 12000);

    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    // console.log(this.pageRole);
    this.pageRole = role.page.find(m => m.page === 'NewOrder');
    if (!(this.pageRole.isWrite || this.pageRole.isRead)) {
      this.NewOrder = false;
    }

    this.pageRole = role.page.find(m => m.page === 'AccpetedOrder');
    if (!(this.pageRole.isWrite || this.pageRole.isRead)) {
      this.AccpetedOrder = false;
    }

    this.pageRole = role.page.find(m => m.page === 'OrderInProgess');
    if (!(this.pageRole.isWrite || this.pageRole.isRead)) {
      this.OrderInProgess = false;
    }

    this.pageRole = role.page.find(m => m.page === 'PassedOrderbyBusinessPartner');
    if (!(this.pageRole.isWrite || this.pageRole.isRead)) {
      this.PassedOrderbyBusinessPartner = false;
    }

    this.pageRole = role.page.find(m => m.page === 'PassedOrderbyDeliveryBoy');
    if (!(this.pageRole.isWrite || this.pageRole.isRead)) {
      this.PassedOrderbyDeliveryBoy = false;
    }

    this.pageRole = role.page.find(m => m.page === 'PickUpOrderList');
    if (!(this.pageRole.isWrite || this.pageRole.isRead)) {
      this.PickUpOrderList = false;
    }
    this.pageRole = role.page.find(m => m.page === 'OrderComplitedbyDeliveryBoy');
    if (!(this.pageRole.isWrite || this.pageRole.isRead)) {
      this.OrderComplitedbyDeliveryBoy = false;
    }

    this.pageRole = role.page.find(m => m.page === 'CancleOrder');
    if (!(this.pageRole.isWrite || this.pageRole.isRead)) {
      this.CancleOrder = false;
    }
    this.pageRole = role.page.find(m => m.page === 'OrderCompletedbyBusinessPartner');
    if (!(this.pageRole.isWrite || this.pageRole.isRead)) {
      this.OrderCompletedbyBusinessPartner = false;
    }
  }

  //  get refresh  token
  refreshToken(userInfo) {
    const url = `${environment.API_URL}/api/Auth/RefreshToken?token=${userInfo.refreshToken}`;
    this.orderService.getOrderList(url).pipe(take(1)).subscribe
      ((response: ResponseModel<IOrders[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.data = response.data;
          localStorage.setItem('info', JSON.stringify(response.data));
        }
      });
  }

  // get order count
  getAllCountList(showLoader: boolean) {
    this.loader = showLoader;
    const url = `${environment.API_URL}/api/Order/StatusWiseCount`;
    this.orderService.getOrderList(url).pipe(take(1)).subscribe
      ((response: ResponseModel<IOrders[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.countAll = response.data;
          this.loader = false;
        }
      });
  }

  getWaiterOrderList() {
    const url = `${environment.API_URL}/api/Table/GetTableOrderList`;
    this.orderService.getOrderList(url).subscribe
      ((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.orderList = response.data;
          this.copyOrderList = response.data;
          // this.config.totalItems = this.orderList.length;
        } else {
          this.orderList = [];
        }
      });
  }

  openWaiterCartList(order: IWaiterOrderListTable) {
    this.dialogService.open(WaiterOrderComponent, {
      context: {
        title: '',
        data: {
          order: order,
          status: 100,
        }
      },
    });
  }

  openMakrOrder() {
    this.dialogService.open(CounterOrderComponent, {
      context: {
        // title: '',
        // data: {
        //   order: order,
        //   status: 100,
        // }
      },
    });
  }
  


  makePayment(order: IWaiterOrderListTable) {    
    this.dialogService.open(WaiterOrderComponent, {
      context: {
        title: '',
        data: {
          order: order,
          status: 101,
        }
      },
    });
  }
  
  makeOrder(sanitizer: DomSanitizer) {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);     
  }

   ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.mySubscription.unsubscribe();
  }

  // getTotalAmount() {
  //   this.subtotal = 0;
  //   this.orders.forEach((item) => {
  //     this.subtotal = this.subtotal + (item.offerPrice * item.quantity);
  //   });
  //   this.tax = this.subtotal * (18 / 100);
  //   this.total = this.subtotal + this.tax;

  // }
}
