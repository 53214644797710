import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormArray, Validators, FormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { IGrain } from '../../models/grain.model';
import { ProductService } from '../../Shared/Services/product.service';
import { ISubGrain } from '../../models/subGrain.model';
import { IProduct } from '../../models/product.model';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { ProductDeleteDialogComponent } from './product-delete-dialog/product-delete-dialog.component';

@Component(
	{
		selector: 'ngx-product-form',
		templateUrl: './product-form.component.html',
		styleUrls: ['./product-form.component.scss'],
	})

export class ProductFormComponent implements OnInit {
	ProductFormArray: FormArray = this.fb.array([]);
	productForm: FormGroup;
	itemForm: FormGroup;
	submitted = false;
	grainList: IGrain[] = [];
	subGrainList: ISubGrain[] = [];
	allSubGrainList: ISubGrain[] = [];
	productId = 0;
	productList = [];
	copyProductList = [];
	pageSize = 0;
	pageNumber = 0;
	totalPrice = 0;

	pageRole: any;
	roleId: number;
	saveBtnHideForRole = true;

	prodctCode = 0;
	selectedType = 1;
	@ViewChild('productFormtest', { static: false }) accordion;

	config = {
		itemsPerPage: 10,
		currentPage: 1,
		totalItems: 0,
	};
	file: any;
	imgPreview = null;
	disabledBtn = false;
	constructor(
		private fb: FormBuilder,
		private productService: ProductService,
		private dialogService: NbDialogService,
		private toastrService: NbToastrService,
	) {
		this.productForm = this.fb.group({
			engName: ['', [Validators.required]],
			hinName: ['', [Validators.required]],
			marName: ['', [Validators.required]],
			active: [true],
			veg: [true],
			tax: [0],
			price: ['', [Validators.required]],
			mrp: ['', [Validators.required]],
			totalPrice: [''],
			type: ['1', [Validators.required]],
			discription: ['', [Validators.required]],
			discriptionMr: ['', [Validators.required]],
			discriptionHn: ['', [Validators.required]],
			imagePath: ['', [Validators.required]],
			quantities: [''],
			barcode: [''],
		});
	}
	clearForm() {
		this.submitted = false;
		this.productForm.reset();
		this.productForm.controls.type.setValue(1);
		this.selectedType = 1;
		this.fileInfo = '';
		this.disabledBtn = false;
		this.prodctCode = 0;
		this.productId = 0;
	}

	ngOnInit() {

		const rolestr = (localStorage.getItem('roleInfo'));
		const role = JSON.parse(rolestr);
		this.pageRole = role.page.find(m => m.page === 'Product Form');
		if (this.pageRole.isWrite === false) {
			this.saveBtnHideForRole = false;
		}

		this.getAllSubGrainList();
		this.getProductList(this.pageSize, this.pageNumber);
		this.productService.getProductUpdateStatus.subscribe(flag => {
			if (flag) {
				this.submitted = false;
				this.productId = 0;
				this.getProductList(0, 0);
			}
		});
	}

	get f() {
		return this.productForm.controls;
	}


	saveProduct() {
		debugger
		this.submitted = true;
		const data: IProduct = {
			id: this.productId,
			typeId: +this.productForm.value.type,
			engName: this.productForm.value.engName,
			hindiName: this.productForm.value.hinName,
			marathiName: this.productForm.value.marName,
			pricePerUnit: +this.productForm.value.price,
			mrp: +this.productForm.value.mrp,
			active: this.productForm.value.active,
			isVegProduct: this.productForm.value.veg,
			tax: 0,
			productDetails: [],
			imageBase64:
				this.imgPreview !== null ? this.imgPreview.split(',')[1] : '',
			discription: this.productForm.value.discription,
			discriptionHn: this.productForm.value.discriptionHn,
			discriptionMr: this.productForm.value.discriptionMr,
			quantities: '1',
			barcode: this.productForm.value.barcode
		};
		if (this.productForm.valid) {
			let apiurl = '';

			if (this.productId === 0) {
				apiurl = `${environment.API_URL}/api/Product/Add`;
			} else { apiurl = `${environment.API_URL}/api/Product/Update`; }

			this.disabledBtn = true;
			this.productService.modifyProduct(apiurl, data).subscribe((response: ResponseModel<any>) => {

				this.disabledBtn = false;
				if (response.status === HttpStatusCode.OK) {
					if (this.productId === 0) {
						this.showToast('top-right', 'success', 'Added Successfully');
					} else {
						this.showToast('top-right', 'success', 'Updated Successfully');
					}
					this.clearForm();
					this.submitted = false;
					this.productId = 0;
					this.file = null;
					this.fileInfo = '';
					this.getProductList(0, 0);
					this.accordion.close();
				} else {
					this.showToast('top-right', 'danger', response.statusMessage);
				}
			});

		}
	}



	openDeleteDialog(product: IProduct) {
		this.dialogService
			.open(ProductDeleteDialogComponent, {
				context: {
					title: '',
					data: product,
				}
			})
			.onClose.subscribe(() => {
				this.getProductList(0, 0);
			});
	}


	fileInfo: string;
	onFileSelect(input: HTMLInputElement): void {
		function formatBytes(bytes: number): string {
			const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
			const factor = 1024;
			let index = 0;
			while (bytes >= factor) {
				bytes /= factor;
				index++;
			}
			return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
		}
		this.file = input.files[0];
		this.uploadFile(this.file);
		this.fileInfo = `${this.file['name']} (${formatBytes(this.file.size)})`;
	}


	uploadFile(files) {
		const file = files;
		const fileReader = new FileReader();
		(fileReader.onload = () => {
			this.imgPreview = fileReader.result.toString();
		}),
			fileReader.readAsDataURL(file);
	}

	pageChange(pageNo) {
		this.config.currentPage = pageNo;
	}


	filter(str: string) {
		str = str.trim();
		if (str !== '') {
			this.productList = this.copyProductList.filter(item => {
				const name = String(item.name_ENG).toLocaleLowerCase();
				const searchStr = String(str).toLowerCase();
				return (name.includes(searchStr));
			});
		} else {
			this.productList = this.copyProductList;
		}
	}

	getProductList(pageSize: number = 10, pageNumber: number = 1) {
		const url = `${environment.API_URL}/api/Product/List?TypeId=0&PageSize=${pageSize}&PageNumber=${pageNumber}`;
		this.productService
			.getProductList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.productList = response.data;
					this.copyProductList = response.data;
					this.config.totalItems = this.productList.length;
				}
			});
	}

	editProduct(Product) {
		this.accordion.open();
		this.prodctCode = Product.productId;
		this.productId = Product.id;
		this.totalPrice = 0;
		this.productForm.controls.engName.setValue(Product.name_ENG);
		this.productForm.controls.hinName.setValue(Product.name_HIN);
		this.productForm.controls.marName.setValue(Product.name_MAR);

		this.productForm.controls.price.setValue(Product.price);
		this.productForm.controls.mrp.setValue(Product.mrp);

		this.productForm.controls.discription.setValue(Product.description);
		this.productForm.controls.discriptionHn.setValue(Product.descriptionHn);
		this.productForm.controls.discriptionMr.setValue(Product.descriptionMr);
		this.productForm.controls.active.setValue(Product.active);
		this.productForm.controls.veg.setValue(Product.isVegProduct);

		this.productForm.controls.quantities.setValue('1');
		this.productForm.controls.barcode.setValue('123456');



		this.selectedType = Product.typeId;
		this.productForm.controls.type.setValue(this.selectedType);


		this.ProductFormArray = this.fb.array([]);

		if (Product.imagePath != null || Product.imagePath !== undefined || Product.imagePath !== '') {

			const imagePathSTR = Product.imagePath;

			const arraystr = imagePathSTR.split('/');

			this.fileInfo = arraystr[arraystr.length - 1];
			this.productForm.controls["imagePath"].clearValidators();
			this.productForm.controls["imagePath"].updateValueAndValidity();

			
		}

	}


	getAllSubGrainList() {
		const url = `${environment.API_URL}/api/GrainCategory/getAllActiveGrainCategory`;
		this.productService
			.getProductList(url)
			.subscribe((response: ResponseModel<ISubGrain[]>) => {
				if (response.status === HttpStatusCode.OK) {
					this.allSubGrainList = response.data;
				}
			});
	}




	showToast(position, status, message) {
		this.toastrService.show(status || 'Success', `${message}`, {
			position,
			status
		});
	}

	selectMe(selecterdValue) {
		this.productForm.controls.type.setValue(selecterdValue);
		this.selectedType = selecterdValue;
	}

}
