import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { IOrder, IWaiterOrderTotalAmount } from '../../models/order.model';
import { HttpStatusCode, ResponseModel } from '../../Shared/responsemodel';
import { BannerService } from '../../Shared/Services/banner.service';
import { OrderService } from '../../Shared/Services/order.service';


@Component({
  selector: 'ngx-waiter-order',
  templateUrl: './waiter-order.component.html',
  styleUrls: ['./waiter-order.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WaiterOrderComponent implements OnInit {

  title: '';
  data: any;
  orderIdFormPlaceOrder = 0;
  waitorForm: FormGroup;
  isSaveDisabled = false;
  selectedTableId: 0;
  submitted = false;
  discountTotal: IWaiterOrderTotalAmount[];
  billPath: '';

  orders: IOrder[];
  subtotal = 0;
  total = 0;
  tax = 0;
  discount = 0;
  discountAmount = 0;
  id = 0;
  discountPer: any;


  constructor(
    public fb: FormBuilder,
    public bannerService: BannerService,
    private toastrService: NbToastrService,
    private dialogRef: NbDialogRef<any>,
    private orderService: OrderService,
    private router: Router


  ) {
    this.waitorForm = this.fb.group({
      tableId: [''],
      amount: [0],
      discountPer: [''],
      discount: [0],
      totalAmount: [0],
    });
  }
  ngOnInit() {
    this.selectedTableId = this.data.order.table.id;
    //console.log(this.selectedTableId);
  }

  showToast(position, status, message) {
    this.toastrService.show(

      status || 'Success',
      `${message}`,
      { position, status });
  }

  close() {
    this.dialogRef.close();
  }

  makePayment(orderdata, discountdata) {
    let url = '';
    this.isSaveDisabled = true;
    const data = {
      tableId: orderdata.order.table.id,
      paymentMode: 1,
      amount: orderdata.order.table.amount,
      discount: discountdata.discount,
      discountPer: Number(this.waitorForm.value.discountPer),
      totalAmount: discountdata.totalAmount

    };

    url = `${environment.API_URL}/api/Order/PlaceOrderFromTable`;
    this.bannerService.addBanner(url, data).subscribe
      ((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success', ' Successfully');
          this.isSaveDisabled = false;
          this.orderIdFormPlaceOrder = response.data.orderId;
          this.printPage();
          this.waitorForm.reset();
          this.submitted = false;
        } else {
          this.showToast('top-right', 'danger', response.statusMessage);
          this.isSaveDisabled = false;
        }
      });
  }


  discountPerOnChange(event) {
    this.discountPer = event.target.value;
    if (this.discountPer != null) {
      this.getTableOrderListbyIdWithDiscount();
    }
  }

  getTableOrderListbyIdWithDiscount() {
    const url = `${environment.API_URL}/api/Table/GetTableOrderListbyIdWithDiscount?tableId=${this.data.order.table.id}&discountPer=${this.discountPer}`;
    this.orderService.getOrderList(url).subscribe
      ((response: ResponseModel<any[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.discountTotal = response.data;
        }
      });

  }

  printPage() {
    const url = `${environment.API_URL}/api/Report/POS_Print?OrderId=${this.orderIdFormPlaceOrder}`;
    this.orderService.getOrderList(url).subscribe
      ((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.billPath = response.data;
           window.open(this.billPath);
          //  window.print();
        }
      });
  }
}
